<template>
  <form @submit.prevent="SubmitForm" ref="emailSub">
    <input class="input" v-model="email" required type="email" name="email" :placeholder="$store.getters.GET_LANG ? 'Введите ваш email' : 'Enter your email'">
    <input class="btn__submit" type="submit" :value="$store.getters.GET_LANG ? 'Подписаться на обновления' : 'Subscribe to updates'">
  </form>
</template>

<script>
export default {
  name: 'Subscribe',
  data () {
    return {
      email: ''
    }
  },
  methods: {
    SubmitForm () {
      const formData = new FormData(this.$refs.emailSub)
      this.$store.dispatch('AddEmailSubscription', formData)
      this.email = ''
    }
  }
}
</script>

<style scoped lang="scss">
.input{
  width: 20.62rem;
}
.btn__submit {
  width: 14.93rem;
  cursor: pointer;
  -webkit-appearance: none;
}

form {
  width: 36.93rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .input {
    background: #EEF4FA;
    border-radius: 3px;
  }
}

@media screen and (max-width: 420px){
  .input{
    width: 100%;
  }
  .btn__submit {
    width: 100%;
    cursor: pointer;
  }

  form {
    width: 100%;
    flex-wrap: wrap;

    .input {
      background: #EEF4FA;
      border-radius: 3px;
    }
    .btn__submit {
      margin-top: 1rem;
      -webkit-appearance: none;
    }
  }
}

</style>
