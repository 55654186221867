<template>
  <section class="bg">

    <img v-for="(news, index) in getImportantNews"
         :class="['main-news__preview', {'active-preview': currentMainNews === index}]"
         :src="news.preview_photo"
         :key="`preview${index}`">

    <div class="fade-block"></div>

    <div class="wrapper-news container">
      <div class="main-news">
        <span class="main-news__type">{{ $store.getters.GET_LANG ? 'главное' : 'main' }}</span>
        <router-link :to="`/news/${getMainNews && getMainNews.id ? getMainNews.id : ''}`">
          <h2 class="main-news__title" v-trim="5" v-if="getMainNews">
            {{ $store.getters.GET_LANG ? getMainNews.title : getMainNews.title_eng }}
          </h2>
        </router-link>
        <!--<div class="main-news__wrapper-date">
          <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.5 0C4.25319 0 0 4.25341 0 9.5C0 14.7468 4.25319 19 9.5 19C14.7468 19 19 14.7468 19 9.5C19 4.25341 14.7468 0 9.5 0ZM9.5 17.2727C5.2073 17.2727 1.72727 13.7929 1.72727 9.5C1.72727 5.2073 5.2073 1.72727 9.5 1.72727C13.7927 1.72727 17.2727 5.2073 17.2727 9.5C17.2727 13.7929 13.7927 17.2727 9.5 17.2727ZM12.7015 11.4803C13.0387 11.8176 13.0387 12.3645 12.7015 12.7017C12.5331 12.8701 12.3118 12.9545 12.0909 12.9545C11.87 12.9548 11.6489 12.8703 11.4803 12.7017L8.88941 10.1108C8.72748 9.94887 8.63636 9.7293 8.63636 9.5V4.31818C8.63636 3.84124 9.02306 3.45455 9.5 3.45455C9.97694 3.45455 10.3636 3.84124 10.3636 4.31818V9.14246L12.7015 11.4803Z"
              fill="#fff"/>
          </svg>
          <p>
            {{ getMainNews && getMainNews.date_time ? getFormattedDate(getMainNews.date_time) : ''}}
          </p>
        </div>-->
      </div>
      <div class="wrapper-last-news">
        <div class="wrapper-other-news" id="wrapper-other-news">

          <div class="news-line" id="newsLine"></div>

          <router-link :class="['other-news',{'current-news-line' : currentMainNews === index}]"
               v-for="(news, index) in getImportantNews"
               :key="`lastOtherNews${index}`"
               :ref="`lastOtherNews${index}`"
               :to="`/news/${news.id}`">
            <h3 class="other-news__title" v-trim="3">
              {{ $store.getters.GET_LANG ? news.title : news.title_eng }}
            </h3>
            <!--<p class="other-news__date">
              {{ getFormattedDate(news.date_time) }}
            </p>-->
          </router-link>

        </div>

        <div class="main-news__stream" v-if="getPublicLiveStreams.length && $store.getters.GET_LANG && isLiveStreamView">
          <hooper @slide="updateCarousel" :wheelControl="false" :touchDrag="false" :autoPlay="true" ref="carousel" :playSpeed="6000"  class="slider-stream" :transition="600">
           <slide v-for="(liveStream, index) in getPublicLiveStreams" :key="`liveStream${index}`" class="slider-stream__item">
             <router-link :to="`/live_stream/${liveStream.id}`">
              <img class="slider-stream__video" :src="liveStream.preview_media">
              <div class="slider-stream__content">
                <div class="slider-stream__content-header">
                  <span v-if="getLiveStreamLabel(liveStream)">
                    {{ getLiveStreamLabel(liveStream) }} {{ getLiveStreamTime(liveStream) }}
                  </span>
                </div>
                <p class="slider-stream__content-text" v-trim="3">
                  {{ liveStream.title }}
                </p>
              </div>
             </router-link>
          </slide>
          </hooper>
          <div class="slider-nav" v-if="getPublicLiveStreams.length > 1">
            <div class="slider-nav__points">
              <span v-for="(item, i) in getPublicLiveStreams" @click="changeSlider(i)" :class="{  'active-point': carouselData === i}" :key="`points${i}`" class="point"></span>
            </div>
            <div class="slider-nav__arrows">
              <svg width="40" height="40" @click.prevent="slidePrev" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.3" r="20" transform="matrix(-1 0 0 1 20 20)" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.4159 23.6015C21.8065 23.992 21.8065 24.6252 21.4159 25.0157L21.3013 25.1304C20.9108 25.5209 20.2776 25.5209 19.8871 25.1304L16.0905 21.3338C16.082 21.3259 16.0737 21.3178 16.0654 21.3096L15.9508 21.1949C15.8522 21.0963 15.7785 20.9823 15.7297 20.8606C15.5844 20.4999 15.6579 20.0716 15.9502 19.7793L16.0649 19.6646C16.0775 19.652 16.0903 19.6398 16.1034 19.628L19.8876 15.8438C20.2781 15.4533 20.9113 15.4533 21.3018 15.8438L21.4165 15.9585C21.807 16.349 21.807 16.9822 21.4165 17.3727L18.3018 20.4874L21.4159 23.6015Z" fill="white"/>
              </svg>
              <svg width="40" height="40" @click.prevent="slideNext" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.3" cx="20" cy="20" r="20" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5841 23.6015C18.1935 23.992 18.1935 24.6252 18.5841 25.0157L18.6987 25.1304C19.0892 25.5209 19.7224 25.5209 20.1129 25.1304L23.9095 21.3338C23.918 21.3259 23.9263 21.3178 23.9346 21.3096L24.0492 21.1949C24.1478 21.0963 24.2215 20.9823 24.2703 20.8606C24.4156 20.4999 24.3421 20.0716 24.0498 19.7793L23.9351 19.6646C23.9225 19.652 23.9097 19.6398 23.8966 19.628L20.1124 15.8438C19.7219 15.4533 19.0887 15.4533 18.6982 15.8438L18.5835 15.9585C18.193 16.349 18.193 16.9822 18.5835 17.3727L21.6982 20.4874L18.5841 23.6015Z" fill="white"/>
              </svg>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'
import formatDate from '@/mixins/formatDate'

export default {
  name: 'MainScreen',

  data () {
    return {
      carouselData: 0,
      currentMainNews: 0,
      importantNewsPositions: [],
      intervalId: null,
      isLiveStreamView: false
    }
  },

  updated () {
    const news = document.getElementsByClassName('other-news')
    this.importantNewsPositions = [].map.call(news, (news) => {
      return news.getBoundingClientRect()
    })
    this.setNewsLinePosition()
  },

  mounted () {
    setTimeout(() => {
      this.isLiveStreamView = true
    }, 2500)
    this.$store.commit('setPaginationLimit', 100)
    this.$store.dispatch('getPublicLiveStreams')
    this.$store.commit('setPaginationLimit', 9)
    setTimeout(this.setNewsLinePosition, 300)
    this.intervalId = setInterval(this.changeNewsLine, 5000)
  },

  beforeDestroy () {
    this.isLiveStreamView = false
    clearInterval(this.intervalId)
  },

  methods: {
    changeNewsLine () {
      if (this.getImportantNews?.length) {
        this.currentMainNews++
        if (this.currentMainNews > 2) {
          this.currentMainNews = 0
        }
        this.setNewsLinePosition()
      }
    },

    setNewsLinePosition () {
      const line = document.getElementById('newsLine')
      const wrapper = document.getElementById('wrapper-other-news').getBoundingClientRect().y
      if (this.importantNewsPositions[this.currentMainNews]) {
        line.style.top = this.importantNewsPositions[this.currentMainNews].y - wrapper + 'px'
        line.style.height = this.importantNewsPositions[this.currentMainNews].height + 'px'
        line.style.opacity = '1'
      }
    },

    slidePrev () {
      this.$refs.carousel.slidePrev()
    },

    slideNext () {
      this.$refs.carousel.slideNext()
    },

    changeSlider (num) {
      this.$refs.carousel.slideTo(num)
    },

    updateCarousel (payload) {
      this.carouselData = payload.currentSlide
    },

    getLiveStreamLabel (liveStream) {
      let dateStart = liveStream.start_date_time
      let dateEnd = liveStream.end_date_time
      let label = ''

      if (dateStart && dateEnd) {
        const now = new Date().getTime()
        dateStart = new Date(dateStart).getTime()
        dateEnd = new Date(dateEnd).getTime()

        if (dateStart < now && dateEnd < now) {
          label = ''
        } else if (dateStart < now && dateEnd > now) {
          label = this.$store.getters.GET_LANG ? 'идет эфир' : 'online'
        } else if (dateStart > now && dateEnd > now) {
          label = this.$store.getters.GET_LANG ? 'скоро начнется' : 'starting soon'
        }
      }

      return label
    },

    getLiveStreamTime (liveStream) {
      const date = new Date(liveStream.start_date_time)
      return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
    }
  },

  computed: {
    getImportantNews () {
      return this.$store.getters.getImportantNews ?? []
    },

    getMainNews () {
      return this.$store.getters.getImportantNews ? this.$store.getters.getImportantNews[this.currentMainNews] : [{}]
    },

    getMainNewsBackground () {
      return {
        backgroundImage: `linear-gradient(to right, rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${this.getMainNews && this.getMainNews.preview_photo ? this.getMainNews.preview_photo : ''})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }
    },

    getPublicLiveStreams () {
      return this.$store.getters.getPublicLiveStreams
        ? this.$store.getters.getPublicLiveStreams.filter((liveStream) => {
          const now = new Date().getTime()
          const dateStart = new Date(liveStream.start_date_time).getTime()
          const dateEnd = new Date(liveStream.end_date_time).getTime()

          return dateStart < now && dateEnd > now
        })
        : []
    }
  },

  components: {
    Hooper,
    Slide
  },

  mixins: [
    formatDate
  ]
}
</script>

<style scoped lang="scss">

  .news-line {
    position: absolute;
    top: 0;
    left: 0;
    width: .1875rem;
    height: 2rem;
    background-color: white;
    transition: .5s;
    opacity: 0;
  }

  .current-news-line {
    /*border-left: .1875rem solid white;*/
  }

  .main-news__preview, .fade-block {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center;
    z-index: -5;
    opacity: 0;
    transition: 2.35s;
  }

  .fade-block {
    background-image: linear-gradient(to right, rgba(0,0,0,.5), rgba(0,0,0,.5));
    opacity: 1;
    transition: none;
    z-index: -4;
  }

  .active-preview {
    opacity: 1;
  }

section {
  width: 100%;
  height: 100vh;
  /*background-image: url('../../assets/temp/index-bg.png');*/
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.2s ease-in-out;
}

.wrapper-news {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between
}
.wrapper-last-news {
  margin-top: -2.2rem;
}
.main-news {
  width: 53rem;

  &__type {
    padding: 0.43rem 0.62rem;
    font-weight: 600;
    font-size: 0.81rem;
    line-height: 123%;
    text-transform: uppercase;
    color: #FFFFFF;
    background: #246CB7;
    border-radius: 3px;
    margin-bottom: 1.81rem;
    display: inline-block;
  }

  &__title {
    font-weight: bold;
    font-size: 3.43rem;
    line-height: 109%;
    color: #FFFFFF;
    margin-bottom: 1.5rem;
  }

  &__wrapper-date {
    display: flex;
    align-items: baseline;

    svg {
      width: 0.81rem;
      margin-right: 0.75rem;
    }

    p {
      font-size: 0.75rem;;
      color: #FFFFFF;
    }
  }
}

.wrapper-other-news {
  padding: 2.2rem 2rem 2.2rem 0;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
}

.other-news {
  width: 20.06rem;
  margin-bottom: 2.5rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    font-weight: 600;
    font-size: 1.05rem;
    line-height: 133%;
    color: #FFFFFF;
    margin-bottom: 0.37rem;
  }

  &__date {
    font-size: 0.75rem;
    line-height: 150%;
    color: #FFFFFF;

  }
}

.main-news__slider {
  border: 1px solid red;
}

.main-news__stream {
  margin-top: 1.62rem;

  * {
    background: none;
  }
}

.slider-stream__item {
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: .7rem;
  }
}

.slider-stream__video {
  width: 10rem;
  height: 7.5rem;
  object-fit: cover;
  object-position: center;
  margin-right: 1.37rem;
  max-width: unset !important;
}

.slider-stream__content-header {
  display: flex;
  margin-bottom: 0.37rem;

  span {
    margin-right: 0.43rem;
    font-weight: 600;
    font-size: 13px;
    line-height: 123%;
    text-transform: uppercase;
    padding: 0.125rem 0.56rem;
    color: #FFFFFF;
    background: #E9242A;
    border-radius: 3px;
  }
}

.slider-stream__content-text {
  /*width: 15.62rem;*/
  font-weight: 600;
  font-size: 1.12rem;
  line-height: 133%;
  color: #FFFFFF;
}

.slider-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__points{
    display: flex;
    align-items: center;
    .point{
      display: block;
      width: 0.312rem;
      height: 0.312rem;
      background: #9CA0A7;
      cursor: pointer;
      border-radius: 50%;
      margin-right: 0.43rem;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &__arrows{
    svg{
      width: 2.5rem;
      height: 2.5rem;
      margin-right: 0.625rem;
      cursor: pointer;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.slider-stream {
  height: auto;
  width: 26.5rem;
}

.active-point{
  background-color: #E9242A !important;
  width: 0.437rem !important;
  height: 0.437rem !important;
}
@media screen and (max-width: 768px){
  section {
    background-position-x: center;
  }
  .wrapper-news {
    flex-direction: column;
    padding: 6rem 0 0;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .wrapper-last-news {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    margin: 1rem auto 0;

    /*overflow: auto;*/
    /*border-bottom: 1px solid #FFFFFF;*/
  }
  .wrapper-other-news {
    display: none;
    /*flex-direction:column;*/
    border-left: none;
    order: 2;
    padding-left:0;
  }

  .main-news__stream {
    margin-top: 0;
  }

  .bg {
    height: 60rem;
    background-position: center;
  }

  .main-news {
    width: 100%;

    &__title {
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 125%;
      margin-bottom: 2.25rem;
    }
  }
  .slider-stream {
    height: auto;
    width: 23.5rem;
  }
  .slider-stream__content-text {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 157%;
    margin-bottom: 0.7rem;
  }
  .slider-stream__item {
    align-items: flex-start;
  }
  .slider-stream__video {
    width: 7rem;
    margin-right: 1rem;
  }

   .other-news {
    max-width: 35rem;
    width:100%;
    margin-bottom: 2.5rem;

    &__title {
      font-weight: 600;
      font-size: 1.15rem;
      line-height: 157%;
      margin-bottom: 0.7rem;
    }
  }
  .slider-nav{
    display: none;
  }
}
@media screen and (max-width: 420px){
  .bg {
    height: 35.625rem;
    background-position: center;
  }
  .wrapper-other-news {
    padding-right: 0;
    width: 240%;
    margin-left: -2rem;
  }
  .other-news {
    max-width: 100%;
    margin-bottom: 2.5rem;
    margin-right: 1rem;
  }
  .wrapper-news {
    padding: 10rem 0 0;
    justify-content: space-between;
  }
  .wrapper-last-news {
    border: none;
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .slider-stream__video {
    width: 4.5rem;
    height:4.5rem;
    border-radius: 4px;
  }
  .news-line{
    display: none;
  }

  .main-news {

    &__type {
      padding: 0.43rem 0.62rem;
      font-weight: 600;
      font-size: 0.81rem;
      line-height: 123%;
      text-transform: uppercase;
      color: #FFFFFF;
      background: #246CB7;
      border-radius: 3px;
      margin-bottom: 1.81rem;
      display: inline-block;
    }

    &__title {
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 127%;
      margin-bottom: 1.5rem;
    }
    &__stream {
      /*margin-top: 4rem;*/
      margin-bottom: 4rem;
    }
  }

  .slider-stream {
    height: auto;
    width: 20rem;

    &__item {
      align-items: flex-start;
      padding-right: 1rem;

      a {
        justify-content: flex-start;
        padding: 0;
      }

    }

    &__video {
      width: 4.68rem;
    }
    &__content-text {
      width: 100%;
      font-size: 0.75rem;
      line-height: 157%;
    }
  }
}

</style>
