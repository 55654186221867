<template>
  <div class="white-container container-no-scroll">
    <line-info v-if="$store.getters.GET_LANG"/>
    <Carousel v-if="$store.getters.GET_LANG" class="banners" count="3" key="infoBanner">
      <hot-line :hotLine="hotLine[0]"/>
      <info-banner :infoBanner="hotLine[1]" />
      <info-banner :infoBanner="hotLine[2]" />
      <!-- getBillDraft[0]  -->
    </Carousel>
    <appeal-block v-if="$store.getters.GET_LANG" class="appeal" />
    <section class="news">
      <tabs-header class="card_containet__tabs" :section="getSection" @click="changeTab($event)"/>
      <tricolor-line class="card_containet__tricolor" />
      <div class="news__list">
        <dynamic-news-card v-for="(news, index) in list"
                           :is-list-view="false"
                           :is-index-page="true"
                           :content="news"
                           :key="`newsCard${index}`"/>
      </div>
    </section>
    <subscribe-form/>
  </div>
</template>
<script>
import AppealBlock from '@/components/indexPage/AppealBlock'
import SubscribeForm from '@/components/forms/SubscribeForm'
import LineInfo from '@/components/indexPage/LineInfo'
import HotLine from '@/components/indexPage/HotLine'
import InfoBanner from '@/components/indexPage/InfoBanner'
import TabsHeader from '@/components/indexPage/TabsHeader'
import TricolorLine from '@/components/TricolorLine'
import Carousel from '@/components/Carousel'
import DynamicNewsCard from '../newsList/DynamicNewsCard'

export default {
  name: 'WhitePart',

  data () {
    return {
      list: []
      // infoBlock1: {
      //   title: 'Доклад ОП РФ',
      //   text: 'О состоянии гражданского общества в России за 2020 год',
      //   img: 'doklad10012021_4.jpg', // 'figure2.png',
      //   type: 'доклад',
      //   link: 'http://report2020.oprf.ru/'
      // },
      // infoBlock2: {
      //   title: 'Обсуждаем',
      //   text: 'Законопроект о переносе начала учебного года на 1 октября',
      //   img: 'zakon10012021_6.jpg', // 'figure3.png',
      //   link: 'http://195.208.58.53/actual'
      // }
    }
  },

  mounted () {
    this.$store.dispatch('setIndexTags')
    // this.$store.dispatch('setProject')
    // this.$store.dispatch('getPublicNews')
    this.$store.dispatch('setFilterBillDraft', { limit: 1 })
    this.$store.dispatch('getMainItemForum', 'now')
    this.$store.dispatch('setBlockHotLine')
  },

  computed: {
    getPublicNews () {
      return this.$store.getters.getPublicNews
    },

    getAllEntities () {
      return this.$store.getters.getAllEntities
    },

    getAllNewsComments () {
      return this.$store.getters.getAllNewsComments
    },

    hotLine () {
      // return {
      //   title: this.$store.getters.GET_LANG ? 'Горячая линия Общественной палаты' : 'Hotline of the Public Chambers',
      //   phone: '8-800-737-77-66',
      //   polls: this.$store.getters.GET_LANG ? 'Звонок из любого региона России бесплатный' : 'Calls from any region of Russia are free',
      //   date: this.$store.getters.GET_LANG ? 'пн-чт с 9 до 18, пт - сб 9:00 до 16:45 ( MSK )' : 'Mon-Thu from 9 am to 6 pm, Fri - Sat 9 am to 4:45 pm (MSK)',
      //   img: 'figure1.png'
      // }
      return this.$store.getters.getBlockHotLine
    },

    getSection () {
      /*
      return {
        title: this.$store.getters.GET_LANG ? 'Новости' : 'News',
        url_link: '/news?tab=All',
        url_name: this.$store.getters.GET_LANG ? 'Все новости' : 'All news',
        news: this.getPublicNews
      }
      */
      return {
        title: this.$store.getters.GET_LANG ? 'Новости' : 'News',
        tabs: this.getProjects,
        url_link: '/news?tab=All',
        url_name: this.$store.getters.GET_LANG ? 'Все новости' : 'All news'
      }
    },

    getBillDraft () {
      return this.$store.getters.getBillDraft.map((low) => {
        return {
          title: low.title,
          text: low.text,
          img: 'figure3.png',
          link: '/public-examination-bills'
        }
      })
    },

    getPublicBlogs () {
      return this.$store.getters.getPublicBlogs
    },

    getProjects () {
      return this.$store.getters.GET_LANG ? ['Новости', 'Комментарии', 'Блоги'].concat(this.$store.getters.getIndexTags.map((project) => {
        console.log(project.title)
        return project.title
      })) : ['News', 'Comments', 'Blogs']
    }
  },

  watch: {
    '$store.getters.GET_MAIN_ITEM_FORUM' () {
      this.setInfoBlock()
    },

    getPublicNews () {
      this.list = this.getPublicNews
    },

    getAllEntities () {
      this.list = this.getAllEntities
    },

    getAllNewsComments () {
      this.list = this.getAllNewsComments
    },

    getPublicBlogs () {
      this.list = this.getPublicBlogs
    }
  },

  methods: {
    changeTab (tab) {
      const tabName = this.getProjects[tab]
      console.log(tabName)
      switch (tabName) {
        case 'Новости':
        case 'News':
          this.$store.dispatch('getAllNews')
          this.list = this.getAllEntities
          break
        case 'Комментарии':
        case 'Comments':
          this.$store.dispatch('getAllNewsComments')
          this.list = this.getAllNewsComments
          break
        case 'Блоги':
        case 'Blogs':
          this.$store.dispatch('getPublicBlogs')
          this.list = this.getPublicBlogs
          break
        default:
          this.$store.commit('setFilterTag', tabName)
          this.$store.commit('setFilterSystemTagList', null)
          this.$store.dispatch('getPublicNews')
          this.list = this.getPublicNews
          this.$store.commit('setFilterTag', null)
          break
      }
    },

    async setInfoBlock () {
      const allForum = await this.$store.getters.GET_MAIN_ITEM_FORUM.regional_forums
      const currentForum = allForum.find(this.isActiveForum)
      // date_end: "2020-12-31"
      // date_start: "2020-10-01"
      // id: 1
      // image: "storage/documents/LUI8FUIDREXa3FyT1608565622.svg"
      // location: "Курган"
      // title: "Уральский форум"
      // currentForum
      if (currentForum !== undefined) {
        // this.infoBlock1.title = `${currentForum.location}  ${this.formattingDate(currentForum.date_start, currentForum.date_end)}`
      }
    },

    isActiveForum (element) {
      const dateStop = new Date(element.date_end)
      // const dateStarts = new Date(element.date_start)
      const currentDate = new Date()
      return dateStop > currentDate // && currentDate > dateStarts
    },

    formattingDate (start, end) {
      const dateStart = new Date(start)
      const dateEnd = new Date(end)
      const locale = 'ru-ru'
      const format = {
        day: 'numeric',
        month: 'long'
      }
      if (dateStart.getMonth() === dateEnd.getMonth()) {
        return `${dateStart.getDate()}-${dateEnd.toLocaleString(locale, format)}`
      } else {
        return `${dateStart.toLocaleString(locale, format)} - ${dateEnd.toLocaleString(locale, format)}`
      }
    }
  },

  components: {
    DynamicNewsCard,
    SubscribeForm,
    AppealBlock,
    LineInfo,
    HotLine,
    InfoBanner,
    TabsHeader,
    TricolorLine,
    Carousel
  }
}
</script>

<style scoped lang="scss">
.banners {
  width: 100%;
}
.news {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  margin-top: 3.125rem;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &:after {
      content: '';
      width: 26.5625rem;
    }

    > * {
      margin-bottom: 2.5rem;
    }
  }
}

  @media screen and (max-width: 768px){
    .banners {
      scrollbar-width: none; /* Firefox */

      .hot-line,
      .info-banner {
        min-width: 21.43rem;
        padding: 1.37rem;
        scroll-snap-align: start;
      }

      div:not(:last-child) {
        margin-right: 0.8rem;
      }

    }
    .appeal {
        margin-top: 3.125rem;
    }
    ::v-deep .white-container{
      margin-top: 4rem;
    }

    .news {
      margin-top: 3.125rem;
      &__list{
        justify-content: center;
      }
      &-banner, &-quote {
        width: 21.43rem;
        padding: 1.37rem;
        margin-bottom: 1.5rem;
      }
    }
  }

  @media screen and (max-width: 420px){
     .banners {
      display: inline-flex;
      justify-content: space-between;
      overflow: auto;
      width: 100%;

      .hot-line,
      .info-banner {
        width: 22.9rem;
        padding: 1.37rem;
        scroll-snap-align: start;
        flex-shrink: 0;

      }
    }
    .news {
      &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .banners {
      display: block;
      height: 15rem;
    }
  }
</style>
