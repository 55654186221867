<template>
  <section class="container-no-scroll">
    <main-screen/>
    <white-part class="white-part"/>

    <section class="card_containet">
      <tabs-header class="card_containet__tabs" :section="section" @click="setActiveMediaTab($event)"/>
      <tricolor-line class="card_containet__tricolor"/>

      <div class="card_containet__members" v-if="currentMediaTab === 1 && $store.getters.GET_LANG">
        <dynamic-news-card v-for="(liveStream, index) in getPublicLiveStreams.slice(0, 3)"
                           :is-list-view="false"
                           :content="liveStream"
                           :key="`liveStreamCard${index}`"/>
      </div>

      <div class="card_containet__members" v-else-if="currentMediaTab === 0 && $store.getters.GET_LANG">
        <video-card v-for="(card, key) in getVideoMedias.slice(0,3)"
                    :card="card"
                    :id="key" class="video-card"
                    :key="`videoCard${key}`"/>
      </div>

      <div class="card_containet__members" v-else-if="($store.getters.GET_LANG && currentMediaTab === 2) || (!$store.getters.GET_LANG && currentMediaTab === 0)">
        <dynamic-news-card v-for="(photoMedia, index) in getPhotoMedias.slice(0, 3)"
                           :is-list-view="false"
                           :content="photoMedia"
                           class="photo__mobile"
                           :key="`photoMediaCard${index}`"/>
      </div>

      <Carousel class="card_containet__members" :count="section.templated.length" key="video">
        <!--<component :is="'video-card'" v-for="(card, key) in getPublicMedias" :card="card" :id="key"
                   :key="`card${key}`"/>-->
      </Carousel>
    </section>

    <!-- <section class="white-container card_containet">
      <tabs-header class="card_containet__tabs" :section="members"/>
      <tricolor-line class="card_containet__tricolor"/>
      <Carousel class="card_containet__members" :count="members.templated.length" key="member">
        <component :is="'card-member-board'" v-for="(member, key) in members.templated" :templated="member"
                   :key="`member${key}`"/>
      </Carousel>
    </section> -->
    <section v-if="$store.getters.GET_LANG" class="white-container structures_container">
      <Carousel class="card_containet__members" :count="structures.length" key="structures">
        <public-structure v-for="(structure, key) in structures" :key="`link${key}`" :card="structure"/>
      </Carousel>
    </section>

  </section>
</template>

<script>
import MainScreen from '@/components/indexPage/MainScreen'
import WhitePart from '@/components/indexPage/WhitePart'
import TabsHeader from '@/components/indexPage/TabsHeader'
import TricolorLine from '@/components/TricolorLine'
// import CardMemberBoard from '@/components/indexPage/CardMemberBoard'
import PublicStructure from '@/components/indexPage/PublicStructure'
import VideoCard from '@/components/indexPage/VideoCard'
import Carousel from '@/components/Carousel'
import currentYear from '@/mixins/currentYear'
import formatDate from '@/mixins/formatDate'
import DynamicNewsCard from '../components/newsList/DynamicNewsCard'

export default {
  name: 'Home',
  mixins: [currentYear, formatDate],
  props: {
    alert: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      carouselData: 0,
      currentMediaTab: 0,
      section: {
        title: this.$store.getters.GET_LANG ? 'Мультимедиа' : 'Multimedia',
        tabs: this.$store.getters.GET_LANG ? ['Видео', 'Трансляции', 'Фото'] : ['Photos'],
        url_link: '/news?tab=Media',
        url_name: this.$store.getters.GET_LANG ? 'Все мультимедиа' : 'All multimedia',
        templated: [
          {
            time: '01:34',
            url: 'fMbYdwm8pSw',
            img: 'video1.png',
            title: 'Критерии и оценки рыбохозяйственного комплекса предприятия ООО БЫБХОЗ',
            data: 'Сегодня 21:00'
          },
          {
            time: '01:34',
            url: 'fMbYdwm8pSw',
            img: 'video2.png',
            title: 'Алексей Вовченко о Всероссийском конкурсе «Семья года»',
            data: 'Сегодня 21:00'
          },
          {
            time: '01:34',
            url: 'fMbYdwm8pSw',
            img: 'video3.png',
            title: '«Экспертная площадка на базе ОП РФ - идеальное место для решения решений',
            data: 'Сегодня 21:00'
          }
        ]
      },
      members: {
        title: this.$store.getters.GET_LANG ? 'Члены общественной палаты' : 'Members of the public chamber',
        url_link: '/members_chamber',
        url_name: this.$store.getters.GET_LANG ? 'К списку всех членов ОП РФ' : 'To the list of all members of the OP RF',
        templated: []
      },
      structures: [
        {
          text: this.$store.getters.GET_LANG ? 'Общественные палаты субъектов Российской Федерации' : 'Public chambers of the constituent entities of the Russian Federation',
          type: 'type1.svg',
          url: '/public_chamber_news',
          links: [{
            name: this.$store.getters.GET_LANG ? 'Новости' : 'News',
            url: '/public_chamber_news'
          }, {
            name: this.$store.getters.GET_LANG ? 'Палаты по округам' : 'Chambers by district',
            url: '/public_chamber_regions'
          }, {
            name: this.$store.getters.GET_LANG ? 'Документы' : 'Documents',
            url: '/public_chamber_materials'
          }]
        },
        {
          text: this.$store.getters.GET_LANG ? 'Общественные советы при федеральных органах исполнительной власти' : 'Public councils under federal executive bodies',
          type: 'type2.svg',
          url: '/public_councils',
          links: [{
            name: this.$store.getters.GET_LANG ? 'Новости' : 'News',
            url: '/community_councils?tab=0'
          }, {
            name: this.$store.getters.GET_LANG ? 'Кандидату' : 'The candidate',
            url: '/community_councils?tab=1'
          }, {
            name: this.$store.getters.GET_LANG ? 'Документы' : 'Documents',
            url: '/community_councils?tab=2'
          }]
        },
        {
          text: this.$store.getters.GET_LANG ? 'ОНК — общественный контроль за обеспечением прав человека в местах принудительного содержания граждан ' : 'PMC - public control over the provision of human rights in places of forced detention of citizens',
          type: 'type3.svg',
          url: '/public_monitoring_commissions?tab=0',
          links: [{
            name: this.$store.getters.GET_LANG ? 'Новости' : 'News',
            url: '/public_monitoring_commissions?tab=0'
          }, {
            name: this.$store.getters.GET_LANG ? 'Информация об образованных ОНК' : 'Information about the formed PMCs',
            url: '/public_monitoring_commissions?tab=1'
          }, {
            name: this.$store.getters.GET_LANG ? 'Документы' : 'Documents',
            url: '/public_monitoring_commissions?tab=3'
          }]
        }
      ]
    }
  },
  created () {
    this.$store.dispatch('apiMemberList', { year: this.getCurrentYear() }) // from mixin
    this.$store.dispatch('getAllNews')
    this.$store.dispatch('getPublicMedias')
    this.$store.dispatch('getPublicLiveStreams')
  },
  computed: {
    getMemberList () {
      return this.$store.getters.getMemberList
    },

    getPublicMedias () {
      return this.$store.getters.getPublicMedias
    },

    getPublicLiveStreams () {
      return this.$store.getters.getPublicLiveStreams
    },

    getVideoMedias () {
      return this.getPublicMedias.filter((media) => {
        return media.media_type_model && media.media_type_model.code === 'VIDEO_REPORT'
      }).map((media) => {
        return {
          title: media.title,
          time: media.media_detail,
          url: media.preview_media,
          media_list: media.media_list,
          img: 'video1.png',
          data: this.getFormattedDate(media.date)
        }
      })
    },

    getPhotoMedias () {
      return this.getPublicMedias.filter((media) => {
        return media.media_type_model && media.media_type_model.code === 'PHOTO_REPORT'
      })
    }
  },
  watch: {
    getMemberList () {
      this.flattedArray()
    },
    '$store.getters.GET_LANG' () {
      this.members.title = this.$store.getters.GET_LANG ? 'Члены общественной палаты' : 'Members of the public chamber'
      this.members.url_name = this.$store.getters.GET_LANG ? 'К списку всех членов ОП РФ' : 'To the list of all members of the OP RF'
      this.$store.dispatch('apiMemberList', { year: this.getCurrentYear() })
      this.structures = [
        {
          text: this.$store.getters.GET_LANG ? 'Общественные палаты субъектов Российской Федерации' : 'Public chambers of the constituent entities of the Russian Federation',
          type: 'type1.svg',
          links: [{
            name: this.$store.getters.GET_LANG ? 'Новости' : 'News',
            url: '/public_chamber_news'
          }, {
            name: this.$store.getters.GET_LANG ? 'Палаты по округам' : 'Chambers by district',
            url: '/public_chamber_regions'
          }, {
            name: this.$store.getters.GET_LANG ? 'Документы' : 'Documents',
            url: '/public_chamber_materials'
          }]
        },
        {
          text: this.$store.getters.GET_LANG ? 'Общественные советы при федеральных органах исполнительной власти' : 'Public councils under federal executive bodies',
          type: 'type2.svg',
          links: [{
            name: this.$store.getters.GET_LANG ? 'Новости' : 'News',
            url: '/community_councils?tab=0'
          }, {
            name: this.$store.getters.GET_LANG ? 'Кандидату' : 'The candidate',
            url: '/community_councils?tab=1'
          }, {
            name: this.$store.getters.GET_LANG ? 'Документы и стандарты' : 'Documents and standards',
            url: '/community_councils?tab=2'
          }]
        },
        {
          text: this.$store.getters.GET_LANG ? 'ОНК — общественный контроль за обеспечением прав человека в местах принудительного содержания граждан ' : 'PMC - public control over the provision of human rights in places of forced detention of citizens',
          type: 'type3.svg',
          links: [{
            name: this.$store.getters.GET_LANG ? 'Новости' : 'News',
            url: '/public_monitoring_commissions?tab=0'
          }, {
            name: this.$store.getters.GET_LANG ? 'Информация об образованных ОНК' : 'Information about the formed PMCs',
            url: '/public_monitoring_commissions?tab=1'
          }, {
            name: this.$store.getters.GET_LANG ? 'Документы' : 'Documents',
            url: '/public_monitoring_commissions?tab=3'
          }]
        }
      ]
      this.section.title = this.$store.getters.GET_LANG ? 'Мультимедиа' : 'Multimedia'
      this.currentMediaTab = 0
      this.section.tabs = this.$store.getters.GET_LANG ? ['Видео', 'Трансляции', 'Фото'] : ['Photos']
      this.section.url_name = this.$store.getters.GET_LANG ? 'Все мультимедиа' : 'All multimedia'
      this.$store.dispatch('getAllNews')
      this.$store.dispatch('getPublicMedias')
    }
  },
  mounted () {
    this.currentMediaTab = 0
    if (this.alert) {
      this.$noty.warning(this.alert)
    }
  },
  methods: {
    setActiveMediaTab (index) {
      this.currentMediaTab = index
    },
    async flattedArray () {
      this.members.templated.length = 0
      const listMember = await Object.values(this.$store.getters.getMemberList)
      const listMemberFlat = listMember.flat()
      const randomList = new Set()
      if (listMemberFlat.length >= 6) {
        let isBreak = false
        while (true) {
          randomList.add(this.randomInteger(0, listMemberFlat.length - 1))
          if ((randomList.size === 6) || (isBreak)) { break }
          setTimeout(() => { isBreak = true }, 1500)
        }
        randomList.forEach(item => this.members.templated.push(listMemberFlat[item]))
      } else {
        this.members.templated = listMemberFlat
      }
      // this.members.templated = listMemberFlat.slice(0, 6)
    },
    randomInteger (min, max) {
      const rand = min + Math.random() * (max + 1 - min)
      return Math.floor(rand)
    }
  },
  components: {
    DynamicNewsCard,
    MainScreen,
    WhitePart,
    TabsHeader,
    TricolorLine,
    // CardMemberBoard,
    PublicStructure,
    VideoCard,
    Carousel
  }
}
</script>

<style scoped lang="scss">
  body {
    background: #101010;
  }

  .card_containet__members {
    flex-wrap: wrap;

    &::after {
      content: '';
      width: 26.56rem;
    }
  }

  .white-part {
    padding: 2.5rem;
    margin-top: -17vh; //-9rem;
  }

  .structures_container {
    display: flex;
    justify-content: space-between;
    margin-top: 3.125rem;
  }

  // section{
  //   margin-bottom: 3.125rem;
  // }

  @media screen and (max-width: 768px) {
    .white-part {
      padding: 1.75rem 1.25rem;
      margin-top: -5.8rem;
    }
    .card_containet {
      padding: 0 1rem;
      margin-top: 3.125rem;
      background: inherit;
      overflow: hidden;

      &__members {
        overflow-x: auto;
        width: 100%;

        .card_wrapper {
          min-width: 10rem;
          height: 12.5rem;
          margin-right: 1.18rem;
        }

        .media-card {
          min-width: 21.5rem;
          height: 14rem;
          margin-right: 1rem;
          box-shadow: 0px 1.25rem 1.75rem rgba(69, 90, 113, 0.35);
          margin-bottom: 3rem;
          scroll-snap-align: start;
        }

        .photo__mobile {
          margin-bottom: 3rem;
        }

      }

    }

    .white-container.structures_container {
      background: inherit;
      overflow-x: auto;
      width: 100%;

      .info-banner {
        min-width: 21.5rem;
        padding: 1.37rem;
        background: #fff;
        margin-right: 0.8rem;
        scroll-snap-align: start;
      }

    }
    .slider__points {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 1.25rem;;

      .point {
        display: block;
        width: 0.312rem;
        height: 0.312rem;
        background: #9CA0A7;
        cursor: pointer;
        border-radius: 50%;
        margin-right: 0.43rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .active-point {
      background-color: #E9242A !important;
      width: 0.437rem !important;
      height: 0.437rem !important;
    }
  }

  @media screen and (max-width: 420px) {
    .white-part {
      margin-top: -1rem;
    }

    .card_containet {
      padding: 0 1rem;
      margin-top: 3.125rem;

      &__members {
        overflow-x: auto;
        width: 100%;

        .card_wrapper {
          min-width: 8.625rem;
          height: 10rem;
          margin-right: 1.18rem;
        }

        .media-card {
          min-width: 18rem;
          height: 14rem;
          margin-right: 1rem;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0);
          // margin-bottom: 0;

        }

      }

    }

    .white-container.structures_container {
      padding: 0 1rem;

      .info-banner {
        min-width: 18rem;
        height: 15.9rem;
      }

      .info-banner:first-child {
        // margin-left: 0.9rem;
      }
    }

    .slider__points {
      justify-content: center;
    }
    .video-card{
      margin-right: 0 !important;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .card_containet {
      display: block;
      height: 27rem;
    }
    .structures_container {
      display: block;
      height: 27rem;

      .info-banner {
        min-width: 33%;
        display: inline;
      }

      .slider__points {
        display: none;
      }
    }
  }

</style>
