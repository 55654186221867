<template>
  <div class="hot-line" :style="`background: linear-gradient(to top right, rgba(77, 105, 135, 0.2), rgba(255, 255, 255, 0.2))`">
    <h3 class="hot-line__title">{{ hotLine.title }}</h3>
    <a class="hot-line__phone" :href="`tel:${hotLine.phone}`">{{ hotLine.phone }}</a>
    <div class="hot-line__polls" v-html="hotLine.text "></div>
    <!-- <p class="hot-line__polls">{{ hotLine.polls }}</p>
    <div class="hot-line__wrapper-date">
      <img src="/assets/icon/clock.svg" alt="icons">
      <p>{{ hotLine.date }}</p>
    </div> -->
    <!-- <div class="hot-line__figure" :style="`background: url('${require('/assets/temp/' + hotLine.img)}') left top no-repeat`"></div> -->
    <div class="hot-line__figure" :style="`background: url('${hotLine.image}') left top no-repeat`"></div>
  </div>
</template>

<script>
export default {
  name: 'HotLine',
  props: {
    hotLine: {
      type: Object,
      required: true,
      default: function () {
        return {
          title: null,
          phone: null,
          polls: null,
          date: null
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.hot-line {
  padding: 1.87rem;
  width: 26.56rem;
  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
  border-radius: 8px;

  overflow: hidden;
  position: relative;

  &__figure {
    position: absolute;
    left: 15rem;
    top: 5rem;
    width: 23rem;
    height: 25rem;
    opacity: 0.2;

    border-radius: 100%;
    background-size: contain !important;
  }

  &__title {
    width: 13.18rem;
    font-weight: 600;
    font-size: 1.12rem;
    line-height: 133%;
    color: #1F3245;
    margin-bottom: 1.25rem;
  }

  &__phone {
    display: inline-block;
    font-weight: bold;
    font-size: 1.43rem;
    line-height: 130%;
    letter-spacing: 2px;
    color: #fff;
    background: #D32E2E;
    border-radius: 8px;
    padding: 0.43rem 1.875rem;
    margin-bottom: 2rem;
  }

  &__polls {
    font-weight: 600;
    font-size: 0.875rem;
    color: rgba(23, 23, 23, 0.8);
    // margin-bottom: 0.875rem;
    ::v-deep {
      * {
        margin: revert;
        padding: revert;
        list-style-type: revert;
        font-size: revert;
        font-weight: revert;
      }
      :first-child{ margin-top: 0; }
      :last-child{ margin-bottom: 0; }
      img {
        width: 1.18rem !important;
        margin-right: 0.62rem;
      }
    }
  }

  // &__wrapper-date {
  //   display: flex;
  //   align-items: center;

  //   img {
  //     width: 1.18rem;
  //     margin-right: 0.62rem;
  //   }

  //   p {
  //     font-size: 0.875rem;
  //     line-height: 157%;
  //     color: rgba(23, 23, 23, 0.8);
  //   }
  // }
}
@media screen and (max-width: 420px){
  .hot-line {
    &__phone {
      font-size: 1.1875rem;
    }

    &__figure {
      position: absolute;
      left: 11rem;
      top: 7rem;
      width: 19rem;
      height: 19rem;
      opacity: 0.2;
      border-radius: 100%;
      background-size: contain !important;
    }
  }
}

</style>
