<template>
  <div class="media-card" :style="getPreviewImage">
    <span class="media-card__btn" @click="playVideo()"></span>
    <p class="media-card__data">{{ card.data }}</p>
    <p v-trim="2" class="media-card__title" >{{ card.title }}</p>
    <div class="media-card__video" :class="{'activ' : isPlay === true}">
      <iframe class="media-card__video" :src="card.media_list[0].link" frameborder="0" v-if="card.media_list && card.media_list.length"></iframe>
      <!--<Youtube class="media-card__video" :video-id="card.url" :ref="`youtube${id}`" @playing="playing" @paused="paused"></Youtube>-->
    </div>
  </div>
</template>

<script>
// import { Youtube } from 'vue-youtube'

export default {
  name: 'VideoCard',

  props: {
    card: {
      type: Object,
      required: true
    },
    id: {
      type: Number
    }
  },

  data () {
    return {
      isPlay: false,
      playerVars: {
        //
      }
    }
  },

  methods: {
    playVideo () {
      this.isPlay = true
      this.player.playVideo()
    },
    playing (val) {
      console.log(val)
    },
    paused () {
      this.isPlay = false
    }
  },

  computed: {
    player () {
      return this.$refs[`youtube${this.id}`].player
    },

    getPreviewImage () {
      return {
        background: `linear-gradient(to right, rgba(0,0,0,.4), rgba(0,0,0,.4)), url(${this.card.url}) center / cover`
      }
    }
  }
  /*
  components: {
    Youtube: Youtube
  }
  */
}
</script>

<style scoped lang="scss">
.media-card {
  position: relative;
  padding: 1.25rem 1.875rem;
  width: 26.56rem;
  height: 16.25rem;

  box-shadow: 0px 25px 35px rgba(69, 90, 113, 0.35);
  border-radius: .5rem;
  -webkit-border-radius: .5rem;
  -moz-border-radius: .5rem;
  -khtml-border-radius: .5rem;
  color: #FFF;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__btn{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3.0625rem;
    width: 3.0625rem;
    margin: auto;

    background-image: url('../../assets/icons/video.svg');
    background-repeat: no-repeat;
    background-size: cover;

    cursor: pointer;
  }

  &__time,
  &__data {
    display: flex;

    font-weight: 600;
    font-size: 0.8125rem;
    line-height: 133%;
  }

  &__data::before {
    content: "";
    width: 0.8125rem;
    height: 0.8125rem;
    margin-right: 0.56rem;

    background-image: url('../../assets/icons/clock_white.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__title {
    font-weight: bold;
    font-size: 1.125rem;
    line-height: 133%;

    margin-top: auto;
  }

}
</style>

<style lang="scss">
  .media-card {

    &__video {
      // box-shadow: 0px 25px 35px rgba(69, 90, 113, 0.35);
      border-radius: .5rem;
      -webkit-border-radius: .5rem;
      -moz-border-radius: .5rem;
      -khtml-border-radius: .5rem;

      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;

      z-index: -1;
    }
  }

  .activ {
    z-index: 10;
  }
</style>
