<template>
  <div v-if="$store.getters.GET_LANG" class="line-info" @mouseover="setIsMoving(false)" @mouseout="setIsMoving(true)">
    <div class="line"
         :style="getFirstPosition"
         ref="firstLine">
      <router-link v-for="(news, index) in getLightningNews"
                   :key="`lightningNews${index}`"
                   :to="`/news/${news.id}`">
        <p>
          {{ getDate(news) }}
        </p>
        <p>
          {{ $store.getters.GET_LANG ? news.short_title : news.short_title_eng}}
        </p>
      </router-link>
    </div>
    <div class="line"
         :style="getSecondPosition"
         ref="secondLine">
      <router-link v-for="(news, index) in getLightningNews"
                   :key="`lightningNews${index}`"
                   :to="`/news/${news.id}`">
        <p>
          {{ getDate(news) }}
        </p>
        <p>
          {{ $store.getters.GET_LANG ? news.short_title : news.short_title_eng}}
        </p>
      </router-link>
    </div>

  </div>
</template>

<script>

export default {
  name: 'LineInfo',

  data () {
    return {
      first_position: 0,
      second_position: 0,
      bounding: [],
      isMoving: true,
      intervalId: null
    }
  },

  watch: {
    '$store.getters.GET_LANG' () {
      this.$store.dispatch('getLightningNews')
    }
  },

  mounted () {
    this.$store.dispatch('getLightningNews')

    this.intervalId = setInterval(() => {
      if (this.isMoving) {
        this.first_position -= 1
        this.second_position -= 1

        this.bounding = [
          this.$refs.firstLine.getBoundingClientRect(),
          this.$refs.secondLine.getBoundingClientRect()
        ]

        if (this.bounding[0].right < 0) {
          this.first_position = this.bounding[1].x + this.bounding[1].width
        }

        if (this.bounding[1].right < 0) {
          this.second_position = this.first_position
        }
      }
    }, 10)
  },

  beforeDestroy () {
    clearInterval(this.intervalId)
  },

  methods: {
    setIsMoving (value) {
      this.isMoving = value
    },

    getDate (news) {
      if (news.date_time) {
        const date = new Date(news.date_time)
        return date.toLocaleString('ru', { day: 'numeric', month: 'numeric' })
      } else {
        return ''
      }
    }
  },

  computed: {
    getLightningNews () {
      return this.$store.getters.getLightningNews
    },

    getFirstPosition () {
      return {
        left: this.first_position + 'px'
      }
    },

    getSecondPosition () {
      return {
        left: this.second_position + 'px'
      }
    }
  }
}

</script>

<style scoped lang="scss">
.line-info {
  display: flex;
  align-items: flex-start;
  width: 100%;
  background: #003E78;
  border-radius: 8px;
  padding: 1.25rem 1.87rem;
  margin-bottom: 3.12rem;
  overflow: hidden;

  .line {
    display: flex;
    flex-direction: row;
    position: relative;
    align-items: center;
    -ms-flex: 0 0 auto;
  }

  p{
    font-weight: 600;
    font-size: 1.12rem;
    line-height: 139%;
    color: #FFFFFF;
    white-space: nowrap;

    &:first-child {
      padding-right: 0.93rem;
      border-right: 1px solid #FFFFFF;
    }

    &:last-child {
      margin-left: 0.93rem;
    }
  }

  a {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5rem;
    -ms-flex: 0 0 auto;
  }
}
@media screen and (max-width: 420px){
  .line-info {
    padding: 0.75rem;
    margin-bottom: 2rem;

    p {
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 157%;
    }
  }
}
</style>
