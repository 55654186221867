<template>
  <section class="appeal" >
    <div>
      <h3 class="appeal__title">{{ $store.getters.GET_LANG ? 'Обращения граждан' : `Citizens' appeals` }}</h3>
      <p class="appeal__text">{{ $store.getters.GET_LANG ? `Вы можете подать обращение — предложение, заявление или жалобу, заполнив форму по ссылке:` : `You can submit an appeal - a proposal, an application or a complaint by filling out the form at the link:` }}</p>
<!--      <router-link class="appeal__links" to="/appeal_forms">Подать обращение</router-link>-->
      <a class="appeal__links" href="https://eis.oprf.ru/treatments/send">{{ $store.getters.GET_LANG ? 'Подать обращение' : `Submit an appeal` }}</a>
    </div>
<!--    <div>-->
<!--      <h4 class="appeal__subtitle">Обращений подано</h4>-->
<!--      <footer>-->
<!--        <p class="appeal__number-all circle-blue">1299</p>-->
<!--        <p class="appeal__number-work">/</p>-->
<!--        <p class="appeal__number-work">215</p>-->
<!--        <p class="appeal__text">в работе</p>-->
<!--      </footer>-->
<!--    </div>-->
<!--    <div>-->
<!--      <h4 class="appeal__subtitle">Решено</h4>-->
<!--      <footer>-->
<!--        <p class="appeal__number-all circle-green">1299</p>-->
<!--        <p class="appeal__number-work">/</p>-->
<!--        <p class="appeal__number-work">215</p>-->
<!--        <p class="appeal__text">в работе</p>-->
<!--      </footer>-->
<!--    </div>-->
  </section>
</template>

<script>
export default {
  name: 'AppealBlock'
}
</script>

<style scoped lang="scss">
.appeal {
  background-image: url("../../assets/bg/bg-appeal.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  padding: 2.06rem 2.37rem 1.75rem 2.06rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.12rem;
}

.appeal {
  &__title {
    font-weight: bold;
    font-size: 2.25rem;
    line-height: 122%;
    letter-spacing: 0.5px;
    color: #fff;
    margin-bottom: 1.37rem;
  }

  &__text {
    max-width: 70rem;
    font-weight: 600;
    font-size: 0.87rem;
    line-height: 150%;
    color: #FFFFFF;
  }

  &__links {
    display: inline-block;
    margin-top: 1.25rem;
    font-weight: bold;
    font-size: 0.87rem;
    line-height: 143%;
    color: #FFFFFF;
    background: #EB494E;
    border-radius: 3px;
    padding: 0.62rem 2.12rem;
  }

  &__subtitle {
    font-weight: bold;
    font-size: 1.12rem;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .circle-blue{
    &::before{
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 1.5rem;
      left: -.25rem;
      width: 2.68rem;
      height: 2.68rem;
      background : #246CB7;
      border-radius: 50%;
    }
  }
  .circle-green{
    &::before{
      content: '';
      position: absolute;
      z-index: -1;
      bottom: 1.5rem;
      left: -.25rem;
      width: 2.68rem;
      height: 2.68rem;
      background : #98D052;
      border-radius: 50%;
    }
  }

  &__number-all {
    position: relative;
    font-weight: bold;
    display: flex;
    z-index: 10;
    color: #FFFFFF;
    font-size: 4.37rem;
  }

  &__number-work {
    font-weight: bold;
    font-size: 2.5rem;
    color: #FFFFFF;
  }
  div {
    width: 70rem;

    footer {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      p:nth-child(2){
        padding: 0 0.45rem;
      }

      p:nth-child(3){
        padding-right: .45rem;
      }
    }
  }
}

  @media screen and (max-width: 768px){
    .appeal {
      padding: 1.375rem 1.56rem 4rem 1.56rem;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 1.25rem;
      position: relative;

      div {
        width: 50%;
      }
      div:first-child {
        width: 100%;
        margin-bottom: 1.25rem;
      }
      div footer{
        margin-bottom: 1rem;
      }

      &__title {
        font-size: 1.25rem;
      }
      &__text{
        width: 60%;
        font-size: 0.75rem;
        font-weight: normal;
        line-height: 157%;
        margin-top: 1.25rem;
      }
      &__links {
        position: absolute;
        bottom: 1.25rem;
        width: 15.6rem;
        text-align: center;
      }
      &__subtitle {
          font-size: 0.75rem;
      }
      &__number-all {
        font-size: 3.75rem;
      }
      &__number-work {
        font-size: 1.875rem;
      }
    }
  }

  @media screen and (max-width: 420px){
    .appeal {
      padding: 1.25rem 1.25rem 4rem;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 1.25rem;

      .circle-blue::before,
      .circle-green::before {
        bottom: 0;
      }

      div {
        width: 100%;
      }
      div footer{
        margin-bottom: 1rem;
      }
      &__title {
        font-size: 1.25rem;
      }
      &__text {
        width: 100%;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 150%;
        margin-bottom: 1.375rem;
      }
      &__links {
        position: absolute;
        bottom: 1.25rem;
        width: 15.6rem;
        text-align: center;
      }
      &__subtitle {
          font-size: 0.75rem;
          margin-bottom: 1.375rem;
      }
      &__number-all {
        font-size: 3.125rem;
      }
      &__number-work {
        font-size: 1.56rem;
      }

    }
  }
</style>
