<template>
  <a :href="infoBanner.link" class="info-banner" :style="`background: linear-gradient(to top right, rgba(77, 105, 135, 0.2), rgba(255, 255, 255, 0.2))`">
    <p class="info-banner__title">{{ infoBanner.tag }}</p>
    <p v-trim="4" class="info-banner__polls" v-html="infoBanner.title"></p>
    <a v-if="infoBanner.document" :href="infoBanner.document" class="info-banner__type">Документ</a>
    <div v-if="infoBanner.image !== ''" class="info-banner__figure" :style="`background: url('${infoBanner.image}') left top no-repeat`"></div>
  </a>
</template>

<script>
export default {
  name: 'InfoBanner',
  props: {
    infoBanner: {
      type: Object,
      required: true,
      default: () => ({
        tag: '',
        title: '',
        text: '',
        image: '',
        // type: '',
        link: '/',
        document: null
      })
    }
  }
}
</script>

<style scoped lang="scss">
.info-banner {
  padding: 1.87rem;
  width: 26.56rem;
  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  position: relative;

  &__figure {
    position: absolute;
    left: 15rem;
    top: 5rem;
    width: 28rem;
    height: 28rem;
    opacity: 0.2;
    border-radius: 100%;
    background-size: contain !important;
  }

  &__title {
    flex: 1;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 114%;
    text-transform: uppercase;
    color: #306392;
    opacity: 0.85;
  }

  &__polls {
    font-style: normal;
    font-weight: 600;
    font-size: 1.12rem;
    line-height: 133%;
    color: #1F3245;
    width: 16.68rem;
    margin-bottom: 1.25rem;
  }

  &__type {
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 133%;
    text-transform: uppercase;
    color: #FFFFFF;
    opacity: 0.85;
    padding: .125rem .625rem;
    background: #082037;
    border-radius: 3px;
  }
}
.info-banner:last-child {
  margin-left:1rem;
}
</style>
