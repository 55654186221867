<template>
  <div class="info-banner">
    <span class="info-banner__type" :style="`background: url('${'/assets/icons/' + card.type}') center / cover`"></span>
    <router-link :to="card.url" class="info-banner__text">{{ card.text }}</router-link>
    <p class="info-banner__links">
      <router-link v-for="(link, key) in card.links" :key="`link${key}`" :to="link.url">{{ link.name }}</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PublicStructure',
  props: {
    card: {
      type: Object,
      required: true,
      default: () => ({
        links: [],
        text: null,
        type: null,
        url: '/'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.info-banner {
  padding: 1.875rem;
  width: 26.56rem;
  height: 18.9375rem;

  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
  border-radius: 8px;
  border: 1px solid #E5E8EE;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.875rem;

  &__type {
    width: 2.8125rem;
    height: 2.8125rem;

    margin-bottom: 1.25rem;
  }

  &__text{
    font-weight: 600;
    line-height: 157%;

    color: #1F3245;
    opacity: 0.9;
  }

  &__links {
    display: flex;
    flex-direction: column;

      a {
        color: #246CB7;
        margin-bottom: 0.5rem;
      }

    margin-top: auto;
  }

}
</style>
